<template>
  <ModuleSubPage fullWidth>
    <BaseHasPermission :permissionNames="['user.update']">
      <v-row>
        <v-col cols="12">
          <BaseBackButton :route="{ name: 'ManagementUserList' }">
            {{ $t("USER_MANAGEMENT.USERS.BTN_LABEL_BACK") }}
          </BaseBackButton>
        </v-col>

        <v-col cols="12">
          <BaseHeadline size="3">
            {{
              $t("USER_MANAGEMENT.USERS.ASSIGN.ROLES_PAGE_TITLE", {
                userName: userName ? userName : "",
              })
            }}
          </BaseHeadline>
        </v-col>
      </v-row>
      <AssignTable
        v-model="roles"
        :fields="['displayName', 'displayName']"
        :loading="isLoading"
        @sync="syncUserRoles($event)"
      />
    </BaseHasPermission>
  </ModuleSubPage>
</template>

<script>
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import AssignTable from "@components/AssignTable";

export default {
  name: "UserAssignRoles",

  data() {
    return {
      isLoading: false,
      roles: {
        assignable: [],
        assigned: [],
      },
      userName: null,
    };
  },

  components: {
    AssignTable,
    ModuleSubPage,
  },

  computed: {
    userId() {
      return parseInt(this.$route.params.userId, 10);
    },
  },

  methods: {
    async loadUserData() {
      try {
        const { data } = await this.$axios.userManagement.showUser({
          userId: this.userId,
          perPage: 1000,
          page: 1,
        });
        this.userName = data.data.email;

        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async loadAssignedRoles() {
      try {
        const { data } = await this.$axios.userManagement.listRoles({
          inUserIds: [this.userId],
          perPage: 1000,
          page: 1,
        });
        this.roles.assigned = data.data;

        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async loadAssignableRoles() {
      try {
        const { data } = await this.$axios.userManagement.listRoles({
          perPage: 1000,
          page: 1,
        });

        this.roles.assignable = data.data;

        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async loadRoles() {
      this.isLoading = true;

      try {
        await this.loadAssignedRoles();
        await this.loadAssignableRoles();

        return Promise.resolve();
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },

    async syncUserRoles(roleIds) {
      this.isLoading = true;

      try {
        await this.$axios.userManagement.syncUserRoles({ userId: this.userId, roleIds });
        await this.loadRoles();
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },

  created() {
    this.loadUserData();
    this.loadRoles();
  },
};
</script>
